import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import './Home.scss';
import { isNil } from 'lodash';
import GradientBtn from 'Components/GradientBtn/GradientBtn';
import { getProducts } from 'Services/productServices';
import heroHandle from 'Assets/images/hero-handle.png';
import heroMessage from 'Assets/images/default-magnets/hero-message.png';
import RecoProducts from 'Components/RecoProducts/RecoProducts';
import HolidayContent from 'Components/HolidayContent/HolidayContent';
import DateBasedDisplay from 'Components/DateBasedDisplay/DateBasedDisplay';
import HeroNotice from 'Components/HeroNotice/HeroNotice';
import OperatingTimeContent from 'Components/OperatingTimeContent/OperatingTimeContent';
import { isWithinHolidaySeason, muziklabanFestival } from 'Utils/holidayUtils';
import CategoryGateModal from './CategoryGateModal';
import LocationAndCategoryComponent from './Components/LocationAndCategoryComponent';
import HolidayMainContent from './HolidayMainContent';
import PickupGateModal from './PickupGateModal';
import DeliveryGateModal from './DeliveryGateModal';
import { Select } from 'antd';
import CookiePrompt from 'Components/Cookie/CookiePrompt';
import TypeAhead from 'Components/TypeAhead/TypeAhead';
import moment from 'moment';
import DynamicBanner from 'Components/DynamicBanner/DynamicBanner';

const { 
  LOCATION_BARANGAY_ID_COOKIE_NAME,
  CATEGORY_GATE_ID_COOKIE_NAME
 } = process.env;


class HomeTPL extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recoProducts: [],
      bestProducts: [],
    };    
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchProducts();
  }

  async fetchProducts() {
    try {

      const { cookies } = this.props;
      let barangayId = cookies.get(LOCATION_BARANGAY_ID_COOKIE_NAME);
      let packaging = cookies.get(CATEGORY_GATE_ID_COOKIE_NAME);
      let filters = `?barangay=${barangayId}&packaging=${packaging}`;

      const response = await getProducts(filters);

      this.setState({
        recoProducts: response['recommended_products'],
        bestProducts: response['best_sellers'],
      });
    } catch (error) {
      this.setState({
        recoProducts: [],
        bestProducts: [],
      });
    }
  }

  handleAddToCart = (productId, quantity) => {
    return () => {
      console.log(quantity, 'add to cart');
      let { addToCart } = this.props;
      addToCart(productId, quantity);
    };
  };

  render() {
    const { recoProducts, bestProducts } = this.state;
    const { auth, showModal, cookies, changeLocationHandler } = this.props;
    const isLoggedIn = !isNil(auth) && 'isLoggedIn' in auth ? auth.isLoggedIn : false;
    const isMuziklaban = isWithinHolidaySeason(muziklabanFestival) === false ? 'hero-bg-container normal' : 'hero-bg-container';
    
    /* checker for selected holiday when the notice banner should show */
    
    // const currDate = moment(new Date()).format('YYYY-MM-DD') // enable this to push in production
    const currDate = moment(); 
    // const currDate = moment(new Date('2023-03-29')).format('YYYY-MM-DD') // static start time for testing

    /* Christmas Break */
    // const showBannerOnThisDays = [
    //   '2022-12-25',
    //   '2023-01-01'
    // ]

    /* Holyweek */
    const showBannerOnThisDays = [
      '2023-10-29',
      '2023-10-30'
    ]

    const bannerStartDate = moment('2024-12-20 00:00', 'YYYY-MM-DD HH:mm');
    const bannerEndDate = moment('2025-01-02 23:59', 'YYYY-MM-DD HH:mm'); // Include the entire day of Jan 2

    // use this for date w/ hours moment(new Date('2023-04-08 22:00:00')).format("YYYY-MM-DD HH:mm")
    // const isBannerShouldShow = showBannerOnThisDays.includes(currDate)

    /** BETWEEN DAYS */
    const disabledCheckoutStart = moment('2024-10-31 22:00');
    const disabledCheckoutEnd = moment('2024-11-02 09:00'); 

    console.log({
      currDate,
      disabledCheckoutStart,
      disabledCheckoutEnd
    })

    // check if banner should show
    // const isBannerShouldShow = moment(currDate).isSameOrBefore(moment(targetEndDate)) ? true : false //until end day
    const isBannerShouldShow = moment(currDate).isBetween(bannerStartDate, bannerEndDate, 'minute', '[]'); // Inclusive of start and end dates

    // const isBannerShouldShow = moment(currDate).isBetween(disabledCheckoutStart, disabledCheckoutEnd); // range
    
    console.log('isBannerShouldShow', isBannerShouldShow)
    // const isBannerShouldShow = false

    return (
      <>
        <Helmet>
          <title>SMB Delivers</title>
          <meta name='description' content={`SMBDelivers.com offers delivery services of your favourite San Miguel Beer brands...`} />
        </Helmet>
        <LocationAndCategoryComponent isLoggedIn={isLoggedIn} changeLocationHandler={changeLocationHandler} cookies={cookies}/>
        
        
        <DynamicBanner showButtonLink={true} />

        {/* <HolidayContent useLocaltime>
        </HolidayContent> */}


        { /* notification - commented for backup
        <section className='notice'>
          <div className='account-notice'>
            
            <h1>Notice to our Members</h1>
            <HolidayContent useLocaltime showWhenNotHoliday>
              <HolidayMainContent/>
            </HolidayContent>
            <HolidayContent useLocaltime showWhenNotHoliday={false}>
              <HolidayMainContent/>
            </HolidayContent>

          </div>
          <TypeAhead /> -- previously commented
          <CookiePrompt /> -- previously commented
        </section>
        */ }

        {/* Selected days notice banner */}
        { isBannerShouldShow ? (
          <section className='notice'>
           <div className='account-notice'>
             <h1>Notice to our Members</h1>
             <HolidayContent useLocaltime showWhenNotHoliday={true}>
               <HolidayMainContent/>
             </HolidayContent>
           </div>
          </section>
        ) : null } 
        

        {isLoggedIn && recoProducts.length > 0 && <RecoProducts products={recoProducts} sectionTitle='RECOMMENDED FOR YOU' shouldLinkToProducts />}

        {bestProducts.length > 0 && <RecoProducts products={bestProducts} sectionTitle='BEST SELLERS' shouldLinkToProducts />}
      </>
    );
  }
}

export default HomeTPL;
